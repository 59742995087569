import React from 'react'
import Index from './Routes/Index'

const App = () => {

  return (
    <>
    <Index />
    </>
  )
}

export default App  