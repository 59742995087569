import React from 'react'
import './notfound.css';

const Notfound = () => {
    return (
        <>
            <div id="main">
                <div class="fof">
                    <h1>404</h1>
                    <h2>أُووبس! الصفحة المطلوبة غير موجودة!</h2>
                </div>
            </div>
        </>
    )
}

export default Notfound
