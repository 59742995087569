import React from "react";
import styled from "styled-components";
import hayyacomLogo from "../../assets/hayyacomLogo.png";
import { BASE_URL, PRIVACY_POLICY_URL } from "../../Config/api";

const Container = styled.div`
  font-family: Arial, sans-serif;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  color: #333;
`;

const MainTitle = styled.h1`
  color: #79000b;
  border-bottom: 2px solid #eee;
  padding-bottom: 10px;
`;

const SectionTitle = styled.h2`
  color: #76140c;
  margin-top: 30px;
`;

const LastUpdated = styled.p`
  color: #666;
  font-style: italic;
`;

const Section = styled.div`
  margin-bottom: 30px;
`;

const List = styled.ul`
  padding-left: 20px;
`;

const NestedList = styled(List)`
  margin-top: 10px;
`;

const PrivacyPolicy = () => {
  return (
    <Container>
      <img src={hayyacomLogo} alt="logo" width={"150px"} />
      <iframe
        style={{
          width: "100%",
          height: "100vh",
          border: "none",
          overflow: "hidden",
        }}
        src={`${PRIVACY_POLICY_URL}#toolbar=0`}
        title="Privacy Policy Document"
        frameBorder="0"
      />
    </Container>
    /*<Container>
      <Section>
        <MainTitle>سياسة الخصوصي</MainTitle>
        <LastUpdated>Last Updated: November 15, 2024</LastUpdated>
      </Section>

      <Section>
        <SectionTitle>1. Introduction</SectionTitle>
        <p>
          This Privacy Policy describes how we collect, use, process, and disclose
          your information across our services. By using our services, you consent
          to the collection and use of information in accordance with this policy.
        </p>
      </Section>

      <Section>
        <SectionTitle>2. Information We Collect</SectionTitle>
        <p>We collect several types of information for various purposes:</p>
        <List>
          <li>
            Personal Information:
            <NestedList>
              <li>Name</li>
              <li>Email address</li>
              <li>Phone number</li>
              <li>Billing information</li>
            </NestedList>
          </li>
          <li>
            Usage Data:
            <NestedList>
              <li>IP address</li>
              <li>Browser type</li>
              <li>Pages visited</li>
              <li>Time and date of visit</li>
            </NestedList>
          </li>
        </List>
      </Section>

      <Section>
        <SectionTitle>3. How We Use Your Information</SectionTitle>
        <p>We use the collected information for various purposes:</p>
        <List>
          <li>To provide and maintain our service</li>
          <li>To notify you about changes to our service</li>
          <li>To provide customer support</li>
          <li>To gather analysis or valuable information</li>
          <li>To detect, prevent and address technical issues</li>
        </List>
      </Section>

      <Section>
        <SectionTitle>4. Data Security</SectionTitle>
        <p>
          The security of your data is important to us. We implement appropriate
          security measures to protect against unauthorized access, alteration,
          disclosure, or destruction of your information.
        </p>
      </Section>

      <Section>
        <SectionTitle>5. Third-Party Services</SectionTitle>
        <p>
          We may employ third-party companies and individuals for the following
          reasons:
        </p>
        <List>
          <li>To facilitate our service</li>
          <li>To provide the service on our behalf</li>
          <li>To perform service-related services</li>
          <li>To assist us in analyzing how our service is used</li>
        </List>
      </Section>

      <Section>
        <SectionTitle>6. Your Rights</SectionTitle>
        <p>You have the right to:</p>
        <List>
          <li>Access your personal data</li>
          <li>Correct inaccurate data</li>
          <li>Request deletion of your data</li>
          <li>Object to our processing of your data</li>
          <li>Request data portability</li>
        </List>
      </Section>

      <Section>
        <SectionTitle>7. Changes to This Policy</SectionTitle>
        <p>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page and
          updating the "Last Updated" date.
        </p>
      </Section>

      <Section>
        <SectionTitle>8. Contact Us</SectionTitle>
        <p>If you have any questions about this Privacy Policy, please contact us:</p>
        <List>
          <li>By email: privacy@example.com</li>
          <li>By phone: (555) 123-4567</li>
          <li>By mail: 123 Privacy Street, Security City, ST 12345</li>
        </List>
      </Section>
    </Container>*/
  );
};

export default PrivacyPolicy;
